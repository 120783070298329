<template>
    <div class="container search-offer">
        <div>
            <page-header title="분야" subtitle="찾고 싶은 분야를 선택하세요." />
            <tag-box>
                <li
                    v-for="(row, key) in genreText"
                    :key="key"
                    :class="{ on: formData.genre && formData.genre.split(',').indexOf(row) !== -1 }"
                    @click="() => {
                        let genreArr = formData.genre ? formData.genre.split(',') : []
                        let idx = genreArr.indexOf(row)
                        if (idx === -1) {
                            genreArr.push(row)
                        } else {
                            genreArr.splice(idx, 1)
                        }
                        formData.genre = genreArr.join(',')
                    }"
                >{{ $lib.t_genre(row) }}</li>
            </tag-box>
        </div>

        <div class="ui-border-line ui-h-1 ui-mt-2 ui-mb-2" style="height: 10px"></div>

        <div>
            <page-header title="지역" />

            <div class="ui-border-line"></div>

            <check-box
                :text="locationText"
                :click="() => $refs.LocationModal.show()"
                :on="!formData.location === false"
                select
            ></check-box>

            <modal
                ref="LocationModal"
                title="지역을 선택해주세요."
                :confirmCb="modal => {
                    const resultArr = Object.keys(modalData.location)
                        .filter(key => modalData.location[key])
                        // .map(key => $config.constant.location[key])
                    formData.location = resultArr.length
                        ? resultArr.indexOf('0') === -1
                        ? resultArr.join(', ')
                        : '0'
                        : '0'
                    inputData.location = Object.assign({}, modalData.location)
                    modal.hide()
                }"
                :hiddenCb="() => {
          modalData.location = Object.assign({}, inputData.location)
        }"
            >
                <ul slot="body">
                    <li
                        class="text-center"
                        v-for="(row, key) in $config.constant.location"
                        :key="key"
                        @click="() => {
                            modalData.location[key] = !modalData.location[key]
                            if (key === '0' && modalData.location[key]) {
                                for (let i in modalData.location) {
                                if (i !== '0') modalData.location[i] = false
                                }
                            } else {
                                modalData.location[0] = false
                            }
                        }"
                        :class="{ on: modalData.location[key] }"
                    >
                        {{row}}
                        <!-- <icon class="icon" :src="`img/common/check${modalData.location[key] ? '-on' : ''}.png`" width="2rem" height="6rem" :cover="false" /> -->
                        <div class="ui-border-line"></div>
                    </li>
                </ul>
            </modal>
        </div>

        <div class="ui-border-line ui-h-1 ui-mb-2" style="height: 10px"></div>

        <div>
            <page-header title="행사 날짜" />

            <div class="ui-border-line"></div>

            <check-box
                :text="playDateLabel"
                :click="() => $refs.PlayDateModal.show()"
                :on="!formData.playDate === false"
                select
            ></check-box>

            <modal
                ref="PlayDateModal"
                title="날짜를 선택하세요"
                max-width="48rem"
                body-max-height="60vh"
                :confirmCb="modal => {
                    formData.playDate = modalData.playDate ? moment(new Date(modalData.playDate).getTime()).format('YYYYMMDD') : null
                    inputData.playDate = modalData.playDate
                    modal.hide()
                }"
                :cancelCb="modal => {
                    modal.hide()
                }"
                :hiddenCb="() => { modalData.playDate = inputData.playDate }"
            >
                <v-date-picker
                    slot="body"
                    is-inline
                    mode="single"
                    locale="ko-KR"
                    :pane-width="458"
                    :theme-styles="themeStyles"
                    :formats="formats"
                    :min-date="new Date()"
                    v-model="modalData.playDate"
                ></v-date-picker>
            </modal>
        </div>

        <div class="ui-border-line ui-h-1 ui-mb-2" style="height: 10px"></div>

        <div>
            <page-header title="지원 가능한 성별" />

            <tag-box>
                <li
                    v-for="(row, key) in  inputData.wgender"
                    :key="key"
                    :class="{ on: formData.wgender && formData.wgender.indexOf(row) !== -1 }"
                    @click="() => {
                        let arr = formData.wgender ? formData.wgender.split(',') : []
                        let idx = arr.indexOf(row)
                        if (idx === -1) {
                            arr.push(row)
                        } else {
                            arr.splice(idx, 1)
                        }
                        formData.wgender = arr.join(',')
                    }"
                >{{row}}</li>
            </tag-box>
        </div>

        <div class="ui-border-line ui-h-1" style="height: 10px"></div>

        <div>
            <check-box
                text="모집 중인 의뢰만 보기"
                :click="() => formData.clientCheck = formData.clientCheck === 'ing' ? 'end' : 'ing'"
                :on="formData.clientCheck === 'ing'"
            ></check-box>
        </div>

        <div class="ui-border-line ui-h-1" style="height: 10px"></div>

        <footer-box submit-text="검색" :submit-cb="() => searchOffer()" :submit-disabled="!validate"></footer-box>
    </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import TagBox from '@/components/common/TagBox'
import CheckBox from '@/components/common/CheckBox'
import Modal from '@/components/common/Modal'
import FooterBox from '@/components/common/FooterBox'
import Icon from '@/components/common/Icon'

export default {
    components: {
        PageHeader,
        TagBox,
        CheckBox,
        Modal,
        FooterBox,
        Icon,
    },
    data() {
        const location = Object.keys(this.$config.constant.location).reduce((result, key) => {
            let val = false

            if (key == '0') val = true

            return Object.assign(result, { [key]: val })
        }, {})

        return {
            formData: {
                genre: null,
                location: '0',
                clientCheck: 'ing',
                mode: 'nodirect',
                playDate: null,
                wgender: '무관',
            },
            inputData: {
                location: Object.assign({}, location),
                playDate: null,
                wgender: ['무관', '남성', '여성'],
            },
            modalData: {
                playDate: null,
                location: Object.assign({}, location),
            },
            // 달력 스타일
            themeStyles: {
                dayContent: {
                    fontSize: '2rem',
                    lineHeight: '8rem',
                    width: '6rem',
                    height: '6rem',
                },
                dayBackground: {
                    width: '6rem',
                    height: '6rem',
                },
            },
            // 달력 포맷
            formats: {
                title: 'YYYY년 MM월',
                weekdays: 'W',
                navMonths: 'MMM',
                input: ['YYYY.MM.DD'],
                dayPopover: 'L',
                data: ['YYYY.MM.DD'],
            },
        }
    },
    computed: {
        genreText: {
            get() {
                return this.$store.state.user.genreText.map(row => row.genre)
            },
        },
        validate() {
            return Object.keys(this.formData).reduce((result, key) => {
                let val = this.formData[key]
                switch (key) {
                    case 'location':
                    case 'playDate':
                    case 'wgender':
                        val = true
                        break
                }
                return result && val ? true : false
            }, true)
        },
        locationText() {
            return Object.keys(this.inputData.location).reduce((result, key) => {
                if (this.inputData.location[key]) {
                    if (result) result += ', '
                    result += this.$config.constant.location[key]
                }
                return result
            }, '')
        },
        playDateLabel() {
            if (!this.formData.playDate) return '날짜를 선택하세요'
            let playDate = this.formData.playDate
            return `${playDate.substr(0, 4)}-${playDate.substr(4, 2)}-${playDate.substr(6, 2)}`
        },
    },
    methods: {
        searchOffer() {
            const formData = Object.assign({}, this.formData)
            if (formData.clientCheck === 'end') delete formData.clientCheck
            this.$router.push({
                path: '/offer/search/result',
                query: formData,
            })
        },
    },
}
</script>

<style lang="scss">
.container.search-offer .page-header .page-header-title {
    font-size: 2.6rem;
}

#page .c-pane-container {
    border: 0 !important;
    background-color: #fff !important;

    .c-header > .c-title-layout.align-center > div > div > div {
        font-size: 2.4rem !important;
    }

    .c-weekdays > div {
        font-size: 2rem;
    }

    .c-weeks .c-day {
        .c-day-backgrounds.c-day-layer > div > div {
            width: 4rem !important;
            height: 4rem !important;
        }
        .c-day-content-wrapper .c-day-content {
            background-color: transparent !important;
        }
    }
}
</style>
